<template>
    
    <div class="h-auto w-auto p-2 flex flex-row items-center mb-2 justify-end comment overflow-hidden relative cursor-pointer" :class="{'commentOpened': opened}">

        <div class="h-full w-11/12 rounded-lg p-2 flex flex-col shadow-md relative" :class="'bg-message-'+mode" @click="opened = !opened">

            <div class="h-auto flex z-20 flex-row justify-end items-center">
                <span class="text-2xs font-bold" :class="'text-font_gray-'+mode">{{data.created_at || moment('DD/MM/YYYY')}}</span>
            </div>

            <div class="h-auto z-20 px-1">
                <span class="text-xs text-right" :class="'text-dfont-'+mode">
                    {{data.description}}
                </span>
            </div>

            <i class="mdi z-10 mdi-message-reply text-5xl absolute bottom-0 right-0 -mb-6 -mr-1" :class="'text-message-'+mode"></i>

        </div>

        <div class="h-full absolute py-2" style="left:100%;">
            <div class="h-full flex justify-center items-center">
                <Request model="Comments" action="delete" :form="{id:data.id}"  @success="onSuccess" @error="onError" v-slot="{request}">
                    <i @click="request" class="text-xl mdi mdi-trash-can-outline text-bad"></i>
                </Request>
            </div>
        </div>

    </div>

</template>

<script>
import { All, Request } from '@/api/components';
import { state } from '@/store';

    export default {
        props:['data'],
        components:{
            All,
            Request
        },
        data(){
            return{
                opened:false
            }
        },
        methods:{
            onSuccess(){
                this.$emit('reload')
            },
            onError(){
                
            }
        },
        computed:{
            mode(){
                return state.mode
            }
        },
        mounted(){
        }
    }
</script>

<style scoped>

    .comment>div{
        transition: all .3s;
        z-index: 2;
    }

    .comment.commentOpened>div{
        transform: translateX(-1.4rem);
    }

</style>