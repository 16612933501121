<template>
    
    <div class="w-full h-full flex-col pt-4 overflow-hidden">

        <div class="h-full w-full flex flex-col py-2 overflow-hidden">

            <All ref="comments" model="Comments" :immediate="true" :data.sync="comments" :config="config" v-slot="{loading}">
                
                <div class="h-full relative overflow-auto" ref="scroll">

                    <div v-if="!loading" class="h-auto">

                        <comment v-for="(el,index) in comments" :key="index" :data="el" @reload="onSuccess" :class="{'mt-26': index == 0}"></comment>

                    </div>

                    <div v-else class="h-20 w-full flex flex-row justify-center items-center">
                        <loader :loading="loading"></loader>
                    </div>

                </div>

            </All>

            <div class="h-auto px-4">
                <div class="h-12 flex-none w-full flex flex-row shadow_box rounded-lg px-2" :class="'bg-module-'+mode">
                    
                    <Request model="Comments" action="create" :form="form" @success="onSuccess" @error="onError" v-slot="{ request, loading }" class="w-full">
    
                        <div v-if="!loading" class="h-auto w-full flex flex-row">
    
                            <div @keypress.enter="request" class="h-full w-5/6 py-2">
                                <input ref="input" type="text" v-model="description" class="w-full h-full rounded-lg px-2 shadow-inner" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            </div>
    
                            <div class="h-full w-1/6 py-2 pl-2 flex flex-row justify-center items-center">
                                <div @click="request" class="h-full w-full rounded-lg bg-red flex flex-row justify-center items-center">
                                    <i class="mdi mdi-send text-white text-xl"></i>
                                </div>
                            </div>
    
                        </div>
    
                        <div v-else class="h-full w-full relative">
                            <loader :loading="loading" />
                        </div>
    
                    </Request>
    
                </div>
            </div>


        </div>

    </div>

</template>

<script>
import comment from '../../components/comment.vue';
import { state } from '@/store';
import { All, Request } from '@/api/components';
import loader from '../../components/loader.vue';

export default {
    components:{
        comment,
        All,
        Request,
        loader
    },
    data(){
        return{
            comments:[],
            description: ''
        }
    },
    methods:{
        async onSuccess(){
            this.$refs['comments'].request();
            this.description = '';

            await this.$nextTick();
            this.$refs.input.focus();
        },
        onError(){
        }
    },
    computed:{
        client(){
            return state.clientSelected
        },
        config(){
            return {
                data:{
                    client_id: state.clientSelected.Id
                }
            }
        },
        form(){
            return{
                description: this.description,
                client_id: state.clientSelected.Id,
            }
        },
        mode(){
            return state.mode
        }
    },
    watch:{
        async comments(){
            await this.$nextTick();

            const div = this.$refs['scroll']
            div.scrollTop = div.scrollHeight - div.clientHeight;
        }
    },
    mounted(){
    }
}
</script>